document.addEventListener('DOMContentLoaded', (event) => {

    /*
    **====================================================================================
    **			FUNKCJE GŁÓWNE
    **====================================================================================
    **/

    function prepend(newElem, elem) {
        elem.outerHTML = newElem + elem.outerHTML;
    }
    function append(newElem, elem) {
        elem.outerHTML = elem.outerHTML + newElem;
    }


    /*
    **====================================================================================
    **			FORMULARZ CF 7
    **====================================================================================
    **/
    //zmienne
    const mrfClass = "mrf-mod", mrfCheck = "mrf-s-check";
    //funkcje
    function resetCF7FormFields() {
        document.querySelectorAll(".ipt-cf input").forEach(function(elem, index) {
            if (["", "email", "tel", "text"].includes(elem.getAttribute("type"))) elem.value = "";
        });
        document.querySelectorAll(".ipt-cf textarea").forEach(function(elem, index) {
            elem.value = "";
        });
        document.querySelectorAll(".mrf-mod__checkbox").forEach(function(elem, index) {
            elem.classList.remove(mrfCheck);
            elem.nextSibling.checked = false;
        });
		document.querySelectorAll(".mrf-mod__radio").forEach(function(elem, index) {
            elem.classList.remove(mrfCheck);
            elem.nextSibling.checked = false;
        });
		for (const cf7Field of document.querySelectorAll(".ipt-cf .wpcf7-form-control")) {
            cf7Field.parentElement.classList.remove("wpcf7-form-control-wrap--active");
        }
    }
    //dodawanie odpowiednich elementów modyfikacji
    document.querySelectorAll(".ipt-cf .wpcf7-checkbox").forEach(function (elem, index) {
        elem.classList.add(mrfClass);
        elem.querySelectorAll("input").forEach(function (input, index) {
            if (input.getAttribute("type") == "checkbox") {
                let mrfSetCheck = "";
                if (input.checked) mrfSetCheck = " "+mrfCheck;
                let prependElement = input;
                if (input.parentNode.tagName == "LABEL") prependElement = input.parentNode;
                prepend("<span class='mrf-mod__checkbox"+mrfSetCheck+"'></span>", prependElement);
            }
        });
    });
    document.querySelectorAll(".ipt-cf .wpcf7-radio").forEach(function (elem, index) {
        elem.classList.add(mrfClass);
        elem.querySelectorAll("input").forEach(function (input, index) {
            if (input.getAttribute("type") == "radio") {
                let mrfSetCheck = "";
                if (input.checked) mrfSetCheck = " "+mrfCheck;
                let prependElement = input;
                if (input.parentNode.tagName == "LABEL") prependElement = input.parentNode;
                prepend("<span class='mrf-mod__radio"+mrfSetCheck+"'></span>", prependElement);
            }
        });
    });
    //zdarzenie click na elementach dodanych
    //checkbox
    for (const elemCheckboxFieldWrap of document.querySelectorAll(".ipt-cf .mrf-mod .mrf-mod__checkbox")) {
        elemCheckboxFieldWrap.addEventListener('click', function(event) {
            elemCheckboxFieldWrap.classList.toggle(mrfCheck);
            let elemcCheckbox = elemCheckboxFieldWrap.nextSibling;
            if (elemcCheckbox.tagName == "LABEL") elemcCheckbox = elemCheckboxFieldWrap.parentNode.querySelector("input");
            if (elemCheckboxFieldWrap.classList.contains(mrfCheck)) {
				elemcCheckbox.checked = true; elemcCheckbox.classList.add("tru");
			}
            else {
                elemcCheckbox.checked = false; elemcCheckbox.classList.add("fals");
            }
        });
    }
    //checkbox etykietka - wykrycie zmiany pola input
    for (const elemCheckboxField of document.querySelectorAll(".ipt-cf .mrf-mod.wpcf7-checkbox .wpcf7-list-item input")) {
        elemCheckboxField.addEventListener('change', function(event) {
            let checkModField = elemCheckboxField.parentElement.previousSibling;
            if (checkModField.classList.contains("mrf-mod__checkbox")) {
                if (elemCheckboxField.checked) checkModField.classList.add(mrfCheck);
                else checkModField.classList.remove(mrfCheck);
            }
        });
    }
    //radio
    for (const elemRadioFieldWrap of document.querySelectorAll(".ipt-cf .mrf-mod .mrf-mod__radio")) {
        elemRadioFieldWrap.addEventListener('click', function(event) {
            let parentMrfMod = elemRadioFieldWrap.parentElement.parentElement;
            if (parentMrfMod.classList.contains("mrf-mod")) {
                for (const elemcRadios of parentMrfMod.querySelectorAll(".mrf-mod__radio")) {
                    elemcRadios.classList.remove(mrfCheck);
                }
            }
            elemRadioFieldWrap.classList.toggle(mrfCheck);
            let elemcRadio = elemRadioFieldWrap.nextSibling;
            if (elemcRadio.tagName == "LABEL") elemcRadio = elemRadioFieldWrap.parentNode.querySelector("input");
            if (elemRadioFieldWrap.classList.contains(mrfCheck)) { elemcRadio.checked = true; elemcRadio.classList.add("tru"); }
            else {
                elemcRadio.checked = false; elemcRadio.classList.add("fals");
            }
        });
    }
    //radio etykietka - wykrycie zmiany pola input
    for (const elemRadioField of document.querySelectorAll(".ipt-cf .mrf-mod.wpcf7-radio .wpcf7-list-item input")) {
        elemRadioField.addEventListener('change', function(event) {
            //usunięcie wszystkich zaznaczeń
            let parentMrfMod = elemRadioField.parentElement.parentElement.parentElement;
            if (parentMrfMod.classList.contains("mrf-mod")) {
                for (const elemRadios of parentMrfMod.querySelectorAll(".mrf-mod__radio")) {
                    elemRadios.classList.remove(mrfCheck);
                }
            }
            let radioModField = elemRadioField.parentElement.previousSibling;
            if (radioModField.classList.contains("mrf-mod__radio")) {
                if (elemRadioField.checked) radioModField.classList.add(mrfCheck);
                else radioModField.classList.remove(mrfCheck);
            }
        });
    }
    //etykietki pól standardowych
    for (const cf7Field of document.querySelectorAll(".ipt-cf .wpcf7-form-control")) {
        let activeClass = "wpcf7-form-control-wrap--active";
        let elemParent = cf7Field.parentElement;
        cf7Field.addEventListener('input', function(event) {
            if (cf7Field.tagName == "INPUT" || cf7Field.tagName == "TEXTAREA") {
                if (cf7Field.value == "") elemParent.classList.remove(activeClass);
                else {
                    if (!elemParent.classList.contains(activeClass)) {
                        elemParent.classList.add(activeClass);
                    }
                }
            }
        });
        if (cf7Field.value != "") {
            if (!elemParent.classList.contains(activeClass)) {
                elemParent.classList.add(activeClass);
            }
        }
        cf7Field.addEventListener('click', function(event) {
            if (cf7Field.tagName == "INPUT" || cf7Field.tagName == "TEXTAREA") {
                document.querySelectorAll(".ipt-cf .wpcf7-form-control-wrap").forEach(function(elemControlWrap, index) {
                    let nextSibling = elemControlWrap.nextElementSibling;
                    if (nextSibling && nextSibling.classList.contains("wpcf7-field-label")) {
                        let elemField = elemControlWrap.querySelector(".wpcf7-form-control");
                        if (elemField.value == "" && (elemField.tagName == "INPUT" || elemField.tagName == "TEXTAREA")) {
                            elemControlWrap.classList.remove(activeClass);
                        }
                    }
                });
                if (!elemParent.classList.contains(activeClass)) {
                    elemParent.classList.add(activeClass);
                }
            }
        });
    }
    //symulacja clicka na submicie (przycisk obsługujący jest innym elementem, a właściwy submit jest ukryty)
    /*const iptcfSubmit = document.querySelector(".ipt-cf__row--submit .ipt-btn--submit");
    if (iptcfSubmit != null) {
        iptcfSubmit.addEventListener("click", function(event) {
            iptcfSubmit.parentNode.querySelector(".wpcf7-submit").click();
        });
    }*/
    //symulacja clicka na resecie (j/w)
    const iptcfReset = document.querySelector(".ipt-cf__row--submit .ipt-btn--reset");
    if (iptcfReset != null) {
        iptcfReset.addEventListener("click", function(event) {
            resetCF7FormFields();
        });
    }
    document.addEventListener('wpcf7mailsent', function (event) {
        resetCF7FormFields();
    }, false);

    //wybór pliku
    const iptcfFile = document.querySelector(".ipt-cf__row--file .ipt-cf__file-btn");
    if (iptcfFile != null) {
        iptcfFile.addEventListener("click", function(event) {
            iptcfFile.parentNode.parentNode.parentNode.querySelector(".ipt-cf__file-field").click();
        });
    }
    const iptcfFileField = document.querySelector(".ipt-cf__file-field");
    if (iptcfFileField != null) {
        iptcfFileField.addEventListener("change", function(event) {
            let thisValArray = iptcfFileField.value.split("\\");
            document.querySelector(".ipt-cf__row--file .ipt-cf__file-name").innerHTML = thisValArray[thisValArray.length - 1];
        });
    }


    /*
    **====================================================================================
    **			SCROLL ANIM
    **====================================================================================
    **/
    let iptAnimElemArray = [];
    let iptAnimElemArrayCounter = 0;
    let iptAnimEventTimeout;
    let iptAnimClass = ".ipt-anim";
    let iptAnimShowClass = "ipt-anim--show";
    let iptAnimOffset = 150;
    let iptLazyClass = "ipt-anim--lazy";
    let iptLazyLoadClass = "ipt-lazy--load";

    document.querySelectorAll(iptAnimClass).forEach(function(elem, index) {
        iptAnimElemArray.push(elem);
    });

    function iptInView(onReload) {
        if (!iptAnimEventTimeout && iptAnimElemArrayCounter < iptAnimElemArray.length) {
            iptAnimEventTimeout = setTimeout(function () {
                iptAnimEventTimeout = null;
                iptAnim(onReload);
            }, 300);
        }
    }
    function iptAnim(onReload) {
        iptAnimElemArray.forEach(function(elemThis, index) {
            if (!elemThis.classList.contains(iptAnimShowClass)) {
                let elementOffsetFromTop = elemThis.getBoundingClientRect().top + window.scrollY;
                let elementOffsetFromBottom = elementOffsetFromTop + elemThis.offsetHeight;
                let viewportScrollTop = window.scrollY;
                let iptAnimOffsetCurrent = iptAnimOffset;
                if (screen.width <= 360) { iptAnimOffsetCurrent = 30; }
                let iptOffsetLazy = -10;
                if (elemThis.classList.contains("ipt-anim--slidebot")) {
                    iptAnimOffsetCurrent = -50;
                    if (screen.width <= 360) { iptAnimOffsetCurrent = -70; }
                    iptOffsetLazy = iptAnimOffsetCurrent - iptAnimOffset;
                }
                if (elemThis.classList.contains("ipt-anim--slidebot-swiper")) {
                    iptAnimOffsetCurrent = 80;
                    if (screen.width <= 360) { iptAnimOffsetCurrent = 100; }
                    iptOffsetLazy = iptAnimOffsetCurrent - iptAnimOffset;
                }
                let viewportScrollBottom = viewportScrollTop + window.innerHeight - iptAnimOffsetCurrent; //window.innerHeight to wysokość fragmentu dokumentu widocznego w oknie przeglądarki
                let viewportScrollBottomLazy = viewportScrollTop + window.innerHeight - iptOffsetLazy;
                //Akcja Lazy rozpoczyna się wcześniej niż akcja Anim
                if (elementOffsetFromBottom > viewportScrollTop && elementOffsetFromTop < viewportScrollBottomLazy) {
                    if (elemThis.classList.contains(iptLazyClass) && !elemThis.classList.contains(iptLazyLoadClass)) {
                        if (elemThis.getAttribute("ipt-src") != null) {
                            elemThis.setAttribute("src", elemThis.getAttribute("ipt-src"));
                            elemThis.removeAttribute("ipt-src");
                            elemThis.classList.add(iptLazyLoadClass);
                        }
                        else {
                            elemThis.querySelectorAll(".ipt-lazy").forEach(function(elemLazy, index) {
                                if (elemLazy.getAttribute("ipt-src") != null && !elemLazy.classList.contains(iptLazyLoadClass)) {
                                    elemLazy.setAttribute("src", elemLazy.getAttribute("ipt-src"));
                                    elemLazy.removeAttribute("ipt-src");
                                    elemLazy.classList.add(iptLazyLoadClass);
                                }
                            });
                        }
                    }
                }
                /*
                elemThis.getBoundingClientRect().top -----> odległość górnej krawędzi elementu od górnej krawędzi przeglądarki - ta wartość zmniejsza się w miarę scrollowania w dół
                window.scrollY -----> aktualna pozycja scrolla
                window.innerHeight -----> wysokość okna widocznego na ekranie
                elemThis.offsetHeight -----> wysokość elementu
                elementOffsetFromTop -----> daje nam stałą pozycję górnej krawędzi elementu od góry strony (bo gdy pierwsza wartość maleje to druga rośnie)
                elementOffsetFromBottom -----> elementOffsetFromTop i do tego wartość wysokości elementu
                viewportScrollTop -----> przyjmuje wartość aktualnej pozycjo scrolla
                viewportScrollBottom -----> aktualna pozycja scrolla + wysokość okna widocznego na ekranie, czyli jeżeli jesteśmy na samej górze, to pierwsza zmienna będzie 0 a druga 0+wysokość okna

                elementOffsetFromBottom > viewportScrollTop ----->
                Gdy elementOffsetFromBottom <= viewportScrollTop to dolna krawędź elementu znika za górną krawędzią okna

                elementOffsetFromTop < viewportScrollBottom ----->
                gdy elementOffsetFromTop <= viewportScrollBottom to górna krawędź elementu zaczyna wychodzić zza dolnej krawędzi okna
                jednak animacja nie może się zaczynać w momencie jak górna krawędź elementu styka się z dolną krawdzią okna bo byśmy nie widzieli tej animacji
                więc elementOffsetFromTop musi pozostać dłużej większy od viewportScrollBottom - więc viewportScrollBottom musimy dodatkowo pomniejszyć o wartość zmienej iptAnimOffsetCurrent
                */
                if (elementOffsetFromBottom > viewportScrollTop && elementOffsetFromTop < viewportScrollBottom) {
                    iptAnimElemArrayCounter++;
                    if (!elemThis.classList.contains(iptAnimShowClass)) elemThis.classList.add(iptAnimShowClass);
                }
            }
        });
    }

    for(event of ["resize", "scroll", "orientationchange"]) {
        window.addEventListener(event, function() {
            iptInView(false);
        });
    }

    iptInView(true);


    /*
    **====================================================================================
    **			SCROLL TO ELEMENT
    **====================================================================================
    **/
    function getElementY(query) {
        if (document.querySelector(query)) return window.pageYOffset + document.querySelector(query).getBoundingClientRect().top;
        else return "";
    }

    function doScrolling(element, duration) {
        let startingY = window.pageYOffset;
        const elementY = getElementY(element);
        // If element is close to page's bottom then window will scroll only to some position above the element.
        let targetY = document.body.scrollHeight - elementY < window.innerHeight ? document.body.scrollHeight - window.innerHeight : elementY;
        let diff = targetY - startingY;
        // Easing function: easeInOutCubic
        // From: https://gist.github.com/gre/1650294
        const easing = function(t) {
            return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
        }
        let start;

        if (!diff) return;

        // Bootstrap our animation - it will get called right before next frame shall be rendered.
        window.requestAnimationFrame(function step(timestamp) {
            if (!start) start = timestamp;
            // Elapsed miliseconds since start of scrolling.
            let time = timestamp - start;
            // Get percent of completion in range [0, 1].
            let percent = Math.min(time / duration, 1);
            // Apply the easing.
            // It can cause bad-looking slow frames in browser performance tool, so be careful.
            percent = easing(percent);

            window.scrollTo(0, startingY + diff * percent);

            // Proceed with animation as long as we wanted it to.
            if (time < duration) {
                window.requestAnimationFrame(step);
            }
        })
    }

    for (const elemc of document.querySelectorAll(".ipt-scroll")) {
        elemc.addEventListener('click', function(event) {
            let hrefID = elemc.getAttribute("href");
            doScrolling(hrefID, 1000);
        });
    }
    for (const elemc of document.querySelectorAll(".ipt-scroll a")) {
        elemc.addEventListener('click', function(event) {
            let hrefID = elemc.getAttribute("href");
            doScrolling(hrefID, 1000);
        });
    }
    if (window.location.hash != "") {
        doScrolling(window.location.hash, 1000);
    }


    /*
    **====================================================================================
    **			PARALLAXATOR
    **====================================================================================
    **/
    let iptParallaxElemArray = [];
    iptParallaxElemArray.push({
        property: "background-position-y",
        setOffsetValueFromTop: true,
        initialBackPosY: 70,
        minWidth: 1100,
        speed: 96,
        elem: document.querySelector(".breadcroumb-boxs")
    });
    iptParallaxElemArray.push({
        property: "background-position-y",
        calculateStart: true,
        minWidth: 1025,
        speed: 88,
        elem: document.querySelector(".ipt-section--wide-back-1")
    });

    /*
        Wstępne wyliczenia pozycji tła na osi Y
    */
    iptParallaxElemArray.forEach(function(thisElem, index) {
        if (thisElem.elem && (!thisElem.minWidth || window.innerWidth >= thisElem.minWidth)) {
            switch (thisElem.property) {
                case "background-position-y":
                    if (thisElem.calculateStart) {
                        if (!thisElem.baseline) thisElem.baseline = 50;
                        let baseLine = window.innerHeight - thisElem.baseline;
                        /*
                            Maksymalne przesunięcie tła do góry następuje w momencie gdy górna krawędż elema styka się z baseLine czyli baseLine + offsetHeight elementu
                            I obliczoną wartość thisElem.initialBackPosY zmniejszamy poprzez zmienną speed
                            Jeżel element zejdzie jeszcze niżej to wtedy nie jest to liczone (wtedy warunek if w case nie jest spełniony)
                        */
                        if (thisElem.setOffsetValueFromTop) {
                            thisElem.initialBackPosY = thisElem.elem.getBoundingClientRect().top + window.scrollY;
                        }
                        else thisElem.initialBackPosY = baseLine + thisElem.elem.offsetHeight;
                        
                        let speed = 100 - thisElem.speed;
                        if (speed < 0 || speed > 100) speed = 10;
                        thisElem.initialBackPosY = (thisElem.initialBackPosY / speed);
                        thisElem.backPosY = -1 * thisElem.initialBackPosY;
                        thisElem.elem.setAttribute("style", "background-position: center " + Math.round(thisElem.backPosY) + "px;");
                    }
                break;
            }
        }
    });

    function iptParallaxInView() {
        iptParallaxElemArray.forEach(function(thisElem, index) {
            if (thisElem.elem && (!thisElem.minWidth || window.innerWidth >= thisElem.minWidth)) {
                /*
                elemThis.getBoundingClientRect().top -----> odległość górnej krawędzi elementu od górnej krawędzi przeglądarki - ta wartość zmniejsza się w miarę scrollowania w dół
                window.scrollY -----> aktualna pozycja scrolla
                window.innerHeight -----> wysokość okna widocznego na ekranie
                elemThis.offsetHeight -----> wysokość elementu
                elementOffsetFromTop -----> daje nam stałą pozycję górnej krawędzi elementu od góry strony (bo gdy pierwsza wartość maleje to druga rośnie)
                elementOffsetFromBottom -----> elementOffsetFromTop i do tego wartość wysokości elementu
                viewportScrollTop -----> przyjmuje wartość aktualnej pozycjo scrolla
                viewportScrollBottom -----> aktualna pozycja scrolla + wysokość okna widocznego na ekranie, czyli jeżeli jesteśmy na samej górze, to pierwsza zmienna będzie 0 a druga 0+wysokość okna

                elementOffsetFromBottom > viewportScrollTop ----->
                Gdy elementOffsetFromBottom <= viewportScrollTop to dolna krawędź elementu znika za górną krawędzią okna

                elementOffsetFromTop < viewportScrollBottom ----->
                gdy elementOffsetFromTop <= viewportScrollBottom to górna krawędź elementu zaczyna wychodzić zza dolnej krawędzi okna
                jeżeli animacja parallaxa ma się zaczynać trochę później (np. 200px po wyściu zza dołu), to zmniejszamy zmienną viewportScrollBottom o elemThis.offset

                Czym jest target i elem?
                elem to element badany pod kątem pokazywania się na ekranie
                target to element, który ma być animowany i znajduje się on wewnątrz elem
                Przykład: jeżeli elem to sekcja hero, a tytuł w tej sekcji to target, to target będzie się animował gdy elem będzie uciekał poza ekran od góry
                Oczywiście elem też może być animowany -> np. tło sekcji w efekcie "background-position-y"
                */
                let parallaxElement = thisElem.elem;
                let elementOffsetFromTop = Math.round(parallaxElement.getBoundingClientRect().top + window.scrollY);
                let elementOffsetFromBottom = Math.round(elementOffsetFromTop + parallaxElement.offsetHeight);
                let viewportScrollTop = Math.round(window.scrollY);
                thisElem.offset = (thisElem.offset != undefined) ? thisElem.offset : 0;
                let viewportScrollBottom = Math.round(viewportScrollTop + window.innerHeight - thisElem.offset);

                if (elementOffsetFromBottom > viewportScrollTop && elementOffsetFromTop < viewportScrollBottom) {
                    let opacityVal;
                    let posX;
                    let posY;

                    switch (thisElem.property) {
                        case "background-position-y":
                            if (parallaxElement) {
                                if (!thisElem.baseline) thisElem.baseline = 50;
                                let baseLine = window.innerHeight - thisElem.baseline;
                                if (parallaxElement.getBoundingClientRect().top <= baseLine && parallaxElement.getBoundingClientRect().top > (-1*parallaxElement.offsetHeight)) {
                                    if (thisElem.setOffsetValueFromTop) baseLine = thisElem.elem.getBoundingClientRect().top + window.scrollY;
                                    thisElem.backPosY = baseLine - parallaxElement.getBoundingClientRect().top;
                                    let speed = 100 - thisElem.speed;
                                    if (speed < 0 || speed > 100) speed = 10;
                                    /*
                                    po załadowaniu strony obliczana jest zmienna thisElem.initialBackPosY: jest to wartość startowego ujemnego przesunięcia tła na osi Y
                                    wiemy, że animacja przesuwania będzie się wykonywać do momentu gdy kontener paralaxu trochę zajdzie za górne okno,
                                    czyli gdy parallaxElement.getBoundingClientRect().top osiągnię pomnożoną przez -1 wysokość kontenera
                                    a zatem znając parametry możemy obliczyć o ile przesunie się tło gdy animacja wyłączy się gdy kontener schowa się u góry
                                    i ta właśnie wartość jest w zmiennej thisElem.initialBackPosY
                                    i ją odejmujemy od wyliczonej zmiennej thisElem.backPosY
                                    */
                                    if (!thisElem.initialBackPosY && thisElem.initialBackPosY != 0) thisElem.initialBackPosY = 200;
                                    thisElem.backPosY = thisElem.backPosY / speed - thisElem.initialBackPosY;
                                }
                                parallaxElement.setAttribute("style", "background-position: center " + Math.round(thisElem.backPosY) + "px;");
                            }
                            break;
                        case "background-position-x":
                            posX = Math.round((window.scrollY - elementOffsetFromTop) / thisElem.speed);
                            parallaxElement.setAttribute("style", "background-position:  " + posX + "px center;");
                            break;
                        case "opacity-out":
                            if (thisElem.target) {
                                if (!thisElem.offset) thisElem.offset = 0;
                                posY = parallaxElement.getBoundingClientRect().top + thisElem.offset;
                                opacityVal = 1 + (posY / 1000) * 1;
                                thisElem.target.style.opacity = opacityVal;
                            }
                            break;
                        case "opacity-in":
                            if (thisElem.target) {
                                if (!thisElem.offset) thisElem.offset = 0;
                                posY = parallaxElement.getBoundingClientRect().top + thisElem.offset;
                                opacityVal = 1 - (posY / 1000) * 1;
                                thisElem.target.style.opacity = opacityVal;
                            }
                            break;
                        case "move-x-left":
                            if (thisElem.target) {
                                thisElem.speed = (thisElem.speed >= 100) ? 99 : thisElem.speed;
                                let speed = 50 - thisElem.speed;
                                speed = 50 + speed;
                                speed = (speed < 0) ? 0 : speed;
                                if (!thisElem.offset) thisElem.offset = 0;
                                posX = parallaxElement.getBoundingClientRect().top + thisElem.offset;
                                posX = Math.round(posX / speed);
                                thisElem.target.style.transform = "translateX(" + posX + "px)";
                            }
                            break;
                        case "move-y-up-down":
                            if (thisElem.target) {
                                let speed = thisElem.speed - 100;
                                speed = (speed < 0) ? (-1*speed)/10 : speed/10;
                                if (!thisElem.offset) thisElem.offset = 0;
                                posY = parallaxElement.getBoundingClientRect().top + thisElem.offset;
                                posY = Math.round((posY * -1) / speed);

                                thisElem.target.style.transform = "translateY(" + posY + "px)";

                                if (thisElem.opacityOut) {
                                    opacityVal = 1 + ((parallaxElement.getBoundingClientRect().top + thisElem.offset) / 1000) * 1;
                                    thisElem.target.style.opacity = opacityVal;
                                }
                            }
                            break;
                        case "move-y-up-down-hero":
                            if (thisElem.target) {
                                let speed = thisElem.speed - 100;
                                speed = (speed < 0) ? (-1*speed)/10 : speed/10;
                                if (!thisElem.offset) thisElem.offset = 0;
                                //gdy hero section nie zaczyna się od samiutkiej góry ekranu (tylko np jest pod nagłówkiem), to trzeba odjąć odstęp między hero a górną krawędzią okna
                                //wyliczamy to w poniższej zmiennej i dodajemy wartość do posY
                                //bez tej wartości jeżeli napis na środku hero jest parallaxowany, to on na początku dostanie już jakąś wartość ujemną (a zatem będzie przesunięty w górę)
                                //a my chcemy żeby miał on początkową wartość przesunięcia równą 0 - tak by było gdyby hero było od samej góry i użylibyśmy opcji "move-y-up-down"
                                let offsetElemFromTop = -1*(parallaxElement.getBoundingClientRect().top + window.scrollY);
                                posY = parallaxElement.getBoundingClientRect().top + offsetElemFromTop;
                                posY = Math.round((posY * -1) / speed);
                                thisElem.target.style.transform = "translateY(" + posY + "px)";

                                if (thisElem.opacityOut) {
                                    opacityVal = 1 + ((parallaxElement.getBoundingClientRect().top + thisElem.offset) / 5000) * 1;
                                    if (opacityVal > 1) opacityVal = 1;
                                    thisElem.target.style.opacity = opacityVal;
                                }
                            }
                            break;
                        case "move-y-down-fromtop":
                            if (thisElem.target) {
                                //jeżeli odległość kontenera paralaksu od górnej krawędzi będzie mniejsza od linii bazowej oraz jeżeli będzie większa od -500
                                //to animacja wykonuje się
                                //-500 to liczba umowna, można dać wysokość elementu by animacja nie zakończyła się gdy górna krawędź elementu dotknie górnej krawędzi okna
                                if (parallaxElement.getBoundingClientRect().top > -500) {
                                    //różnica pomiędzy linią bazową a odległością pomiędzy górną krawędzią kontenera paralaksu od górnej krawędzi okna
                                    //jeżeli, np. baseLine = 800, a parallaxElement.getBoundingClientRect().top - 700 to thisElem.posY = 100 i o tyle przesuwamy nasz target
                                    thisElem.posY = window.scrollY;
                                    //oczywiście żeby to głupio nie wyglądało to przykładową wartość 100 musimy zmniejszyć
                                    //dlaczego jest 100 - thisElem.speed? Bo thisElem.speed to parametr - liczba od 0 do 100: chcemy żeby zwiększenie tej liczby zwiększło prędkość przesuwania
                                    //jednak im większa liczba tym to przesunięcie będzie wolniejsze, więc żeby to było logicznie od odejmujemy liczbę od 100
                                    let speed = 100 - thisElem.speed;
                                    if (speed < 0 || speed > 100) speed = 10;
                                    thisElem.posY = thisElem.posY / speed;
                                    if (thisElem.posY < 0) thisElem.posY = 0; //element nie przesunie się wyżej niż jego pozycja bazowa
                                }
                                if (thisElem.posY) thisElem.target.setAttribute("style", "transform: translateY(" + Math.round(thisElem.posY) + "px)");
                            }
                            break;
                        case "move-y-down":
                            if (thisElem.target) {
                                if (!thisElem.baseline) thisElem.baseline = 200;
                                let baseLine = window.innerHeight - thisElem.baseline; //domyślnie 200px od dolnej krawędzi okna
                                //jeżeli odległość kontenera paralaksu od górnej krawędzi będzie mniejsza od linii bazowej oraz jeżeli będzie większa od -500
                                //to animacja wykonuje się
                                //-500 to liczba umowna, można dać wysokość elementu by animacja nie zakończyła się gdy górna krawędź elementu dotknie górnej krawędzi okna
                                if (parallaxElement.getBoundingClientRect().top <= baseLine && parallaxElement.getBoundingClientRect().top > -500) {
                                    //różnica pomiędzy linią bazową a odległością pomiędzy górną krawędzią kontenera paralaksu od górnej krawędzi okna
                                    //jeżeli, np. baseLine = 800, a parallaxElement.getBoundingClientRect().top - 700 to thisElem.posY = 100 i o tyle przesuwamy nasz target
                                    thisElem.posY = baseLine - parallaxElement.getBoundingClientRect().top;
                                    //oczywiście żeby to głupio nie wyglądało to przykładową wartość 100 musimy zmniejszyć
                                    //dlaczego jest 100 - thisElem.speed? Bo thisElem.speed to parametr - liczba od 0 do 100: chcemy żeby zwiększenie tej liczby zwiększło prędkość przesuwania
                                    //jednak im większa liczba tym to przesunięcie będzie wolniejsze, więc żeby to było logicznie od odejmujemy liczbę od 100
                                    let speed = 100 - thisElem.speed;
                                    if (speed < 0 || speed > 100) speed = 10;
                                    thisElem.posY = thisElem.posY / speed;
                                    if (thisElem.posY < 0) thisElem.posY = 0; //element nie przesunie się wyżej niż jego pozycja bazowa
                                }
                                if (thisElem.posY) thisElem.target.setAttribute("style", "transform: translateY(" + Math.round(thisElem.posY) + "px)");
                            }
                            break;
                        case "move-y-up":
                            if (thisElem.target) {
                                if (!thisElem.baseline) thisElem.baseline = 200;
                                let baseLine = window.innerHeight - 200;
                                if (parallaxElement.getBoundingClientRect().top <= baseLine && parallaxElement.getBoundingClientRect().top > -500) {
                                    thisElem.posY = baseLine - parallaxElement.getBoundingClientRect().top;
                                    let speed = 100 - thisElem.speed;
                                    if (speed < 0 || speed > 100) speed = 10;
                                    thisElem.posY = thisElem.posY / speed;
                                    if (thisElem.posY > 0) thisElem.posY *= -1;
                                }
                                if (thisElem.posY) thisElem.target.setAttribute("style", "transform: translateY(" + Math.round(thisElem.posY) + "px)");
                            }
                            break;
                    }
                }
            }
        });
    }

    for(event of ["resize", "scroll", "orientationchange"]) {
        window.addEventListener(event, function() {
            window.requestAnimationFrame(iptParallaxInView);
        });
    }

    const iptParallaxTimeOut = setTimeout(function() {
        iptParallaxInView();
    }, 500);


    /*
    **====================================================================================
    **			TILE IMG NUMBER
    **====================================================================================
    **/
    document.querySelectorAll(".skry-tile-img__wrapper").forEach(function(elemWrap, index) {
        let number = 1;
        elemWrap.querySelectorAll(".skry-tile-img__item").forEach(function(elemItem, index) {
            let numberString = number;
            if (number < 10) numberString = "0"+number;
            elemItem.querySelector(".skry-tile-img__head-number").innerHTML = numberString;
            number++;
        });
    });


    /*
    **====================================================================================
    **		SWIPER BRANDS
    **====================================================================================
    **/
    let swiper = new Swiper('.swiper-container--brands',{
        init: true,
        autoplay: {
            delay: 1000,
            disableOnInteraction: false
        },
        loop: true,
        direction: 'horizontal',
        slidesPerView: "auto",
        spaceBetween: 0,
        //touchEventsTarget: 'container',
        initialSlide: 0,
        speed: 750,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        }
    });


    /*
    **====================================================================================
    **		SWIPER TESTIMONIALS
    **====================================================================================
    **/
    let swiperTesti = new Swiper('.swiper-container--testi-list',{
        init: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false
        },
        loop: true, //muszą być minimum 4 slajdy
        direction: 'horizontal',
        slidesPerView: 2,
        //centeredSlides: false,
        //centeredSlidesBounds: true,
        spaceBetween: 0,
        //touchEventsTarget: 'container',
        initialSlide: 0,
        //enabled: false,
        speed: 1500,
        /*navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },*/
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        breakpoints: {
            901: {
                slidesPerView: 2
            },
            100: {
                slidesPerView: 1
            }
        }
    });


    /*
    **====================================================================================
    **		SWIPER MINI GALLERY
    **====================================================================================
    **/
    let swiperMiniGal = new Swiper('.swiper-container--minigal',{
        init: true,
        effect: "cube",
        grabCursor: true,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        loop: true,
        spaceBetween: 0,
        initialSlide: 0,
        speed: 950
    });

    /*
    **====================================================================================
    **			SEARCHER
    **====================================================================================
    **/
    let iptSearcherBtnActiveClass = "ipt-searcher__button--active";
    let iptSearcherFormWrapActiveClass = "ipt-searcher__form-wrapper--active";
    const iptSearcher = document.querySelector("#ipt-searcher__container");
    const iptSearcherFormWrap = document.querySelector("#ipt-searcher__form");
    const iptSearcherBtn = iptSearcher.querySelector("#ipt-searcher__search-button");
    if (iptSearcherBtn != null) {
        iptSearcherBtn.addEventListener("click", function(event) {
            iptSearcherBtn.classList.toggle(iptSearcherBtnActiveClass);
            iptSearcherFormWrap.classList.toggle(iptSearcherFormWrapActiveClass);
        });
    }
    const iptSearcherFullPageBtnClose = document.querySelector("#ipt-searcher__full-page-close");
    if (iptSearcherFullPageBtnClose != null) {
        iptSearcherFullPageBtnClose.addEventListener("click", function(event) {
            iptSearcherBtn.classList.remove(iptSearcherBtnActiveClass);
            iptSearcherFormWrap.classList.remove(iptSearcherFormWrapActiveClass);
        });
    }


	/*
    **====================================================================================
    **			SWITCHER DO GRIDÓW - do wywalenia w gotowym projekcie!
    **====================================================================================
    **/
	jQuery(".skry-grid__row.skry-grid__row--t-flex").each(function() {
        let gThis = jQuery(this);
        gThis.find(".skry-grid__col").each(function() {
            let cThis = jQuery(this);
            cThis.prepend('<div class="grid-col-incdec-wrap"><button class="grid-col-incdec-btn" data-id="dec">-</button><button class="grid-col-incdec-btn" data-id="inc">+</button><span class="grid-col-incdec-class"></span></span></div>');
        });
    });
    jQuery(".skry-grid__row").on("click", ".grid-col-incdec-btn", function() {
        let bThis = jQuery(this);
        let wrapThis = bThis.parent();
        let sClassText = wrapThis.find(".grid-col-incdec-class");
        let bpThis = wrapThis.parent();
        let bpClass = bpThis.attr("class");
        const regex = /skry-grid__col--[0-9]{1,2}/g;
        bpClass = bpClass.match(regex);
        if (bpClass[0]) {
            let decClass = bpClass[0].replace("skry-grid__col--", "");
            let cNumber = parseInt(decClass);
            if (bThis.attr("data-id") == "dec") cNumber -= 1;
            else if (bThis.attr("data-id") == "inc") cNumber += 1;
            if (cNumber == 0) cNumber = 1;
            else if (cNumber == 21) cNumber = 20;
            decClass = "skry-grid__col--" + cNumber;
            sClassText.html(decClass);
            bpThis.removeClass(bpClass[0]);
            bpThis.addClass(decClass);
        }

    });
});